import React from 'react'
import Wrapper, { IIconProps } from './Wrapper'

export default (props: IIconProps) => {
  return (
    <Wrapper {...props}>
      <svg
        viewBox='0 0 24 24'
        height='1em'
        width='1em'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill='none' fillRule='evenodd'>
          <path d='m0 0h24v24h-24z' />
          <path
            d='m12 20.063c.963 0 1.75-.788 1.75-1.75h-3.5c0 .962.787 1.75 1.75 1.75zm5.25-5.25v-4.376c0-2.686-1.426-4.934-3.938-5.53v-.595c.001-.725-.586-1.312-1.312-1.312s-1.313.586-1.313 1.313v.595c-2.502.595-3.937 2.834-3.937 5.53v4.374l-1.75 1.751v.875h14v-.875zm-1.75.874h-7v-5.25c0-2.169 1.321-3.937 3.5-3.937s3.5 1.768 3.5 3.938v5.25zm-7.95-12.257-1.43-1.43c-2.4 1.83-3.98 4.65-4.12 7.85h2c.15-2.65 1.51-4.97 3.55-6.42zm12.39 6.42h2c-.15-3.2-1.73-6.02-4.12-7.85l-1.42 1.43c2.02 1.45 3.39 3.77 3.54 6.42z'
            fill='currentColor'
            fillRule='nonzero'
          />
        </g>
      </svg>
    </Wrapper>
  )
}
