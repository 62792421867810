import React from 'react'
import Wrapper, { IIconProps } from './Wrapper'

export default (props: IIconProps) => {
  return (
    <Wrapper {...props}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 24 24'
        width='1em'
        height='1em'
      >
        <g fill='none' fillRule='evenodd'>
          <g>
            <g>
              <path
                fill='currentColor'
                fillRule='nonzero'
                d='M14.94 15.06L11.887 12 14.94 8.94 14 8 10 12 14 16z'
                transform='translate(-450 -84) translate(450 84)'
              />
              <path
                d='M0 0L24 0 24 24 0 24z'
                transform='translate(-450 -84) translate(450 84)'
              />
            </g>
          </g>
        </g>
      </svg>
    </Wrapper>
  )
}
