import { Playground, Props } from 'docz';
import { Button, Space, IconUser, IconLogout } from '@madup-inc/mad-design';
import * as React from 'react';
export default {
  Playground,
  Props,
  Button,
  Space,
  IconUser,
  IconLogout,
  React
};