import React from 'react'
import { classNames } from '@madup-inc/utils'
import './Button.scss'
import { Space } from './Space'

interface IProps {
  /**
   * 버튼의 타입을 지정합니다.
   */
  type?: 'primary' | 'default' | 'dashed' | 'text'
  size?: 'small' | 'middle' | 'large'
  danger?: boolean
  ghost?: boolean
  /**
   * 둥근 버튼을 만듭니다.
   * @default false
   */
  round?: boolean
  disabled?: boolean
  children: React.ReactNode
  onClick?: any
  style?: any
  htmlType?: 'button' | 'reset' | 'submit'
  className?: string
}

export const Button: React.FC<IProps> = ({
  type = 'default',
  size = 'middle',
  round = false,
  children,
  onClick,
  disabled = false,
  style,
  htmlType = 'button',
  className,
  danger = false,
  ghost = false,
}) => {
  return (
    <button
      onClick={onClick}
      style={style}
      type={htmlType}
      disabled={disabled}
      className={classNames('mad-button', className, size, {
        round,
        danger,
        ghost,
        [type]: Boolean(type),
      })}
    >
      {Array.isArray(children) ? (
        <Space size='small'>{children}</Space>
      ) : (
        <span>{children}</span>
      )}
    </button>
  )
}
