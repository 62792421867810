import React from 'react'
import Wrapper, { IIconProps } from './Wrapper'

export default (props: IIconProps) => {
  return (
    <Wrapper {...props}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='1em'
        height='1em'
        viewBox='0 0 24 24'
      >
        <g fill='none' fillRule='evenodd'>
          <g>
            <g>
              <path
                d='M0 0L24 0 24 24 0 24z'
                transform='translate(-934 -84) translate(934 84)'
              />
              <path
                fill='currentColor'
                fillRule='nonzero'
                d='M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z'
                transform='translate(-934 -84) translate(934 84)'
              />
            </g>
          </g>
        </g>
      </svg>
    </Wrapper>
  )
}
