// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---readme-md": () => import("./../../../../README.md" /* webpackChunkName: "component---readme-md" */),
  "component---src-components-alert-mdx": () => import("./../../../../src/components/Alert.mdx" /* webpackChunkName: "component---src-components-alert-mdx" */),
  "component---src-components-button-mdx": () => import("./../../../../src/components/Button.mdx" /* webpackChunkName: "component---src-components-button-mdx" */),
  "component---src-components-checkbox-mdx": () => import("./../../../../src/components/Checkbox.mdx" /* webpackChunkName: "component---src-components-checkbox-mdx" */),
  "component---src-components-icon-mdx": () => import("./../../../../src/components/Icon.mdx" /* webpackChunkName: "component---src-components-icon-mdx" */),
  "component---src-components-image-mdx": () => import("./../../../../src/components/Image.mdx" /* webpackChunkName: "component---src-components-image-mdx" */),
  "component---src-components-image-viewer-mdx": () => import("./../../../../src/components/ImageViewer.mdx" /* webpackChunkName: "component---src-components-image-viewer-mdx" */),
  "component---src-components-input-mdx": () => import("./../../../../src/components/Input.mdx" /* webpackChunkName: "component---src-components-input-mdx" */),
  "component---src-components-radio-mdx": () => import("./../../../../src/components/Radio.mdx" /* webpackChunkName: "component---src-components-radio-mdx" */),
  "component---src-components-select-mdx": () => import("./../../../../src/components/Select.mdx" /* webpackChunkName: "component---src-components-select-mdx" */),
  "component---src-components-space-mdx": () => import("./../../../../src/components/Space.mdx" /* webpackChunkName: "component---src-components-space-mdx" */),
  "component---src-components-tag-mdx": () => import("./../../../../src/components/Tag.mdx" /* webpackChunkName: "component---src-components-tag-mdx" */),
  "component---src-components-tooltip-mdx": () => import("./../../../../src/components/Tooltip.mdx" /* webpackChunkName: "component---src-components-tooltip-mdx" */),
  "component---src-index-mdx": () => import("./../../../../src/index.mdx" /* webpackChunkName: "component---src-index-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

