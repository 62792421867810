import React, { useRef, useState } from 'react'
import { ISelect } from './type'
import { IconArrowDown } from '../icons'
import { classNames } from '@madup-inc/utils'
import './Select.scss'
import { useOutSideClick } from '../../hooks'

export default ({
  value,
  options,
  size,
  onChange,
  placeholder = '선택',
}: ISelect) => {
  const [isOpen, setIsOpen] = useState(false)
  const $selectList = useRef<HTMLDivElement>(null)

  const handleChangeValue = (value: string) => {
    if (onChange) onChange(value)
    setTimeout(() => setIsOpen(false), 400)
  }

  useOutSideClick(
    $selectList,
    () => setTimeout(() => setIsOpen(false), 400),
    isOpen,
  )

  const getLabel = () =>
    value
      ? options.find((option) => option.value === value)?.label
      : placeholder

  return (
    <div className='mad-select-wrapper' ref={$selectList}>
      <input type='hidden' value={value} />
      <div
        className={classNames('mad-select', size, { open: isOpen })}
        onClick={() => setIsOpen(!isOpen)}
      >
        <p className='mad-select-value'>{getLabel()}</p>
        <IconArrowDown size='16' />
      </div>
      <ul className={classNames('mad-select-list', size, { open: isOpen })}>
        {options?.map((option) => (
          <li
            className={classNames('mad-select-item', {
              selected: value === option.value,
            })}
            key={option.value}
            onClick={() => handleChangeValue(option.value)}
          >
            {option.label}
          </li>
        ))}
      </ul>
    </div>
  )
}
