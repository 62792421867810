import React from 'react'
import Wrapper, { IIconProps } from './Wrapper'

export default (props: IIconProps) => {
  return (
    <Wrapper {...props}>
      <svg
        viewBox='0 0 24 24'
        height='1em'
        width='1em'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill='none' fillRule='evenodd'>
          <path
            d='m12 13.286c-2.663 0-8 1.337-8 4v2.857h16v-2.857c0-2.663-5.337-4-8-4zm6.046 4.903h-12.092v-.903c.115-.56 2.96-2.046 6.046-2.046s5.931 1.486 6.046 2.046zm-6.046-6.046c2.537 0 4.571-2.046 4.571-4.572 0-2.525-2.034-4.571-4.571-4.571-2.526 0-4.56 2.046-4.56 4.571 0 2.526 2.034 4.572 4.56 4.572zm0-7.189c1.44 0 2.606 1.177 2.606 2.617s-1.166 2.619-2.606 2.619c-1.429 0-2.606-1.178-2.606-2.618s1.177-2.618 2.606-2.618z'
            fill='currentColor'
            fillRule='nonzero'
          />
          <path d='m0 0h24v24h-24z' />
        </g>
      </svg>
    </Wrapper>
  )
}
