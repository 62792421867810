import React, { ReactElement, useState } from 'react'
import { classNames } from '@madup-inc/utils'
import './Checkbox.scss'

interface IOptiopns {
  label?: string | React.ReactElement
  value?: string | number
  iconSize?: string
  fillMode?: string
  icon?: string | ReactElement
  shape?: string
  color?: string
  disabled?: boolean
  ref?: React.RefObject<HTMLInputElement>
}
interface ICheckboxProps extends IOptiopns {
  className?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  checked?: boolean
  defaultChecked?: boolean
  children: React.ReactElement
}
interface ICheckboxGroupProps {
  className?: string
  defaultValues?: any[]
  options?: (IOptiopns | string)[]
  disabled?: boolean
  onChange?: (checkedList: any[]) => void
  children?: React.ReactNode
}

export const CheckboxGroup: React.FC<ICheckboxGroupProps> = ({
  options,
  className,
  defaultValues,
  onChange,
  children,
}) => {
  const [checkedList, setCheckedList] = useState(defaultValues || [])

  const onChangeList = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = e.target
    const newList = checked
      ? [...checkedList, value]
      : checkedList.filter((list) => list !== value)
    setCheckedList(newList)
    onChange?.(newList) // eslint-disable-line
  }

  return (
    <div className={classNames('mad-checkbox-group', className)}>
      {options
        ? options.map((option: any, index: number) => {
            const isObject = typeof option === 'object'
            const { value, label, ...restOption } = isObject
              ? option
              : { value: '', label: '' }

            const defaultChecked = Boolean(
              checkedList.find((list) => list === (isObject ? value : option)),
            )

            return isObject ? (
              <Checkbox
                key={`${value}_${index}`}
                value={value || index}
                {...restOption}
                onChange={onChangeList}
                defaultChecked={defaultChecked}
              >
                {label || ''}
              </Checkbox>
            ) : (
              <Checkbox
                key={`${option}_${index}`}
                value={option}
                onChange={onChangeList}
                defaultChecked={defaultChecked}
              >
                {option}
              </Checkbox>
            )
          })
        : React.Children.map(children, (child: React.ReactNode) => {
            if (!React.isValidElement<ICheckboxProps>(child)) {
              return child
            }
            const defaultChecked = Boolean(
              checkedList.find((list) => list === child.props.value),
            )
            return React.cloneElement(child, {
              defaultChecked,
              onChange: onChangeList,
            })
          })}
    </div>
  )
}

export const Checkbox: React.FC<ICheckboxProps> = ({
  className,
  onChange,
  icon = '✓', // ✓ ✔ ✕
  iconSize = 'md',
  fillMode,
  shape,
  children,
  color,

  ...restProps
}) => {
  return (
    <div className={classNames('mad-checkbox', className)}>
      <input type='checkbox' onChange={onChange} {...restProps} />
      <div className={classNames('mad-checkbox-state', color)}>
        <i className={classNames('icon', iconSize)}>{icon}</i>
        <label className={classNames(shape, 'fill', fillMode)}>
          {children}
        </label>
      </div>
    </div>
  )
}
