import { useEffect } from 'react'

export const useOutSideClick = (
  $dom: React.RefObject<any>,
  onClick: () => void,
  option: Boolean,
) =>
  useEffect(() => {
    function handleClickOutside(e: MouseEvent): void {
      if (option && $dom.current && !$dom.current.contains(e.target as Node)) {
        onClick()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [$dom, onClick, option])
