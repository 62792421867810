import { Playground, Props } from 'docz';
import { Select, Space } from '@madup-inc/mad-design';
import { useState } from 'react';
import * as React from 'react';
export default {
  Playground,
  Props,
  Select,
  Space,
  useState,
  React
};