import React from 'react'
import Wrapper, { IIconProps } from './Wrapper'

export default (props: IIconProps) => {
  return (
    <Wrapper {...props}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='1em'
        height='1em'
        viewBox='0 0 24 24'
      >
        <g fill='none' fillRule='evenodd'>
          <g>
            <g>
              <path
                d='M0 0L24 0 24 24 0 24z'
                transform='translate(-1481 -84) translate(1481 84)'
              />
              <path
                fill='currentColor'
                fillRule='nonzero'
                d='M4 20h2.91v-9.6H4V20zm16-8.8c0-.88-.655-1.6-1.455-1.6h-4.589l.691-3.656.022-.256c0-.328-.124-.632-.32-.848L13.58 4 8.792 9.272c-.27.288-.43.688-.43 1.128v8c0 .88.655 1.6 1.455 1.6h6.546c.603 0 1.12-.4 1.338-.976l2.196-5.64c.066-.184.102-.376.102-.584v-1.528l-.007-.008L20 11.2z'
                transform='translate(-1481 -84) translate(1481 84)'
              />
            </g>
          </g>
        </g>
      </svg>
    </Wrapper>
  )
}
