import React from 'react'
import Wrapper, { IIconProps } from './Wrapper'

export default (props: IIconProps) => {
  return (
    <Wrapper {...props}>
      <svg
        viewBox='0 0 24 24'
        height='1em'
        width='1em'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill='none' fillRule='evenodd'>
          <path d='m0 0h24v24h-24z' />
          <path
            d='m0 14h16v-2h-16zm0-6h16v-2h-16zm0-8v2h16v-2z'
            fill='currentColor'
            fillRule='nonzero'
            transform='translate(4 5)'
          />
        </g>
      </svg>
    </Wrapper>
  )
}
