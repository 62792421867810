import React, { useContext, useEffect, useState } from 'react'
import { classNames } from '@madup-inc/utils'
import './Radio.scss'

interface IRadioGroupProps {
  defaultValue?: any
  disabled?: boolean
  /**
   * 라디오 스타일 설정
   */
  optionType?: 'default' | 'outline' | 'card'
  /**
   * 라디오 정렬 방향설정 기본값 horizontal
   */
  direction?: 'horizontal' | 'vertical'
  value?: any
  onChange?: (value: any) => void
  children: React.ReactNode // 타입을 React.ReactElement<IRadioButtonProps>[] 로 특정할 경우 use case 가 제한적임. ex) 라디오버튼들을 Space 로 감싸는 경우
}

interface IRadioButtonProps {
  disabled?: boolean
  value: any
  /**
   * 라디오 안의 내용물로 쓰이는 텍스트나 리액트 컴포넌트이다.
   * optionType이 default 스타일이면 label로 쓰이고 card, outline 스타일 때는 카드 안에 내용물로 쓰인다.
   */
  content?: any
  /**
   * card 스타일 기본 padding 적용 여부
   * 기본값 false
   */
  hasPadding?: boolean
}

const RadioContext = React.createContext<{
  checkedValue: any
  setCheckedValue: any
  disabled: boolean
  optionType: 'default' | 'outline' | 'card'
  onChange: any
  // name?: string
}>({
  checkedValue: undefined,
  setCheckedValue: undefined,
  disabled: false,
  optionType: 'default',
  onChange: () => {},
})

export const RadioGroup: React.FC<IRadioGroupProps> = ({
  defaultValue,
  disabled = false,
  // name,
  optionType = 'default',
  direction = 'horizontal',
  value,
  onChange,
  children,
}) => {
  const [checkedValue, setCheckedValue] = useState<any>(defaultValue)
  const Provider = RadioContext.Provider
  useEffect(() => {
    return value && setCheckedValue(value)
  }, [value])
  return (
    <Provider
      value={{
        checkedValue,
        setCheckedValue,
        disabled,
        optionType,
        onChange,
        // name,
      }}
    >
      <div className={classNames('mad-radio-group', optionType, direction)}>
        {children}
      </div>
    </Provider>
  )
}

export const RadioButton: React.FC<IRadioButtonProps> = ({
  disabled = false,
  value,
  content,
  hasPadding = false,
}) => {
  const context = useContext(RadioContext)
  const handleCheck = () => {
    context.setCheckedValue(value)
    context.onChange(value)
  }
  return (
    <label
      className={classNames(
        'mad-radio-button',
        context.optionType !== 'default' && context.optionType,
        {
          checked: context.checkedValue === value,
          disabled,
          hasPadding,
        },
      )}
      onChange={() => !disabled && handleCheck()}
    >
      <input
        checked={context.checkedValue === value}
        readOnly
        value={value}
        type='radio'
      />
      <div className='mad-radio-main'>{content}</div>
    </label>
  )
}
