import { Alert, Space, IconEdit } from '@madup-inc/mad-design';
import { Playground, Props } from 'docz';
import * as React from 'react';
export default {
  Alert,
  Space,
  IconEdit,
  Playground,
  Props,
  React
};