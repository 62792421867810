import React from 'react'
import Wrapper, { IIconProps } from './Wrapper'

export default (props: IIconProps) => {
  return (
    <Wrapper {...props}>
      <svg
        viewBox='0 0 24 24'
        height='1em'
        width='1em'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill='none' fillRule='evenodd'>
          <path
            d='m3.5 18.49 6-6.01 4 4 8.5-9.56-1.41-1.41-7.09 7.97-4-4-7.5 7.51z'
            fill='currentColor'
            fillRule='nonzero'
          />
          <path d='m0 0h24v24h-24z' />
        </g>
      </svg>
    </Wrapper>
  )
}
