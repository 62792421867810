import React, { ReactNode, useState } from 'react'
import { classNames } from '@madup-inc/utils'
import { IconClose, IconGuide } from './icons'
import './Alert.scss'

interface IAlertProps {
  type: 'info' | 'success' | 'warning' | 'error'
  showIcon?: boolean
  banner?: boolean
  closable?: boolean
  description?: ReactNode
  icon?: ReactNode
  message?: ReactNode
  bgColor?: string
  fontColor?: string
  borderColor?: string
}

export function Alert({
  type = 'info',
  showIcon = true,
  banner = false,
  closable = false,
  description = '',
  icon,
  message = '',
  bgColor,
  fontColor,
  borderColor,
}: IAlertProps) {
  const [visible, setVisible] = useState(true)
  const [alertOut, setAlertOut] = useState(false)
  return (
    <div
      className={classNames('mad-alert', type, { visible, banner, alertOut })}
      style={{
        background: bgColor,
        color: fontColor,
        borderColor: borderColor,
      }}
    >
      <div className='mad-alert-message'>
        {showIcon && (
          <span className='mad-alert-message-icon'>
            {icon || <IconGuide />}
          </span>
        )}
        <span className='mad-alert-message-text'>{message}</span>
      </div>
      <div className='mad-alert-description'>{description}</div>
      {closable && (
        <div
          className='mad-alert-close'
          onClick={() => {
            setAlertOut(true)
            setTimeout(() => setVisible(false), 200)
          }}
        >
          <IconClose />
        </div>
      )}
    </div>
  )
}
