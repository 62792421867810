import React, { useRef, useState } from 'react'
import { ISelect } from './type'
import { IconArrowDown } from '../icons'
import { Checkbox } from '../Checkbox'
import { classNames } from '@madup-inc/utils'
import './Select.scss'
import { useOutSideClick } from '../../hooks'

export default ({
  value,
  options,
  size,
  onChange,
  placeholder = '선택',
}: ISelect) => {
  const [isOpen, setIsOpen] = useState(false)
  const [values, setValues] = useState((value as string[]) || [])
  const handleChangeValue = (value: string) => {
    setValues(
      values.includes(value as string)
        ? values.filter((val) => val !== value)
        : [...values, value as string],
    )
  }
  const $selectList = useRef<HTMLDivElement>(null)
  useOutSideClick(
    $selectList,
    () =>
      setTimeout(() => {
        setIsOpen(false)
        if (onChange) onChange(values)
      }, 400),
    isOpen,
  )

  const getLabel = () => {
    return values.length <= 0
      ? placeholder
      : options
          .reduce(
            (acc, option) =>
              values.includes(option.value) ? [...acc, option.label] : acc,
            [] as string[],
          )
          .join(',')
  }

  return (
    <div className='mad-select-wrapper' ref={$selectList}>
      <input type='hidden' value={value || ''} />
      <div
        className={classNames('mad-select', size, { open: isOpen })}
        onClick={() => setIsOpen(!isOpen)}
      >
        <p className='mad-select-value'>{getLabel()}</p>
        <IconArrowDown size='16' />
      </div>
      <ul className={classNames('mad-select-list', size, { open: isOpen })}>
        {options?.map((option) => (
          <li
            className={classNames('mad-select-item', {
              selected: value === option.value,
            })}
            key={option.value}
            onClick={() => {
              handleChangeValue(option.value)
            }}
          >
            <Checkbox
              key={option.value}
              checked={values?.includes(option.value)}
              onChange={(e) => e.stopPropagation()}
            >
              <div>{option.label}</div>
            </Checkbox>
          </li>
        ))}
      </ul>
    </div>
  )
}
