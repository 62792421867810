import React from 'react'
import Wrapper, { IIconProps } from './Wrapper'

export default (props: IIconProps) => {
  return (
    <Wrapper {...props}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='1em'
        height='1em'
        viewBox='0 0 24 24'
      >
        <g fill='none' fillRule='evenodd'>
          <g>
            <g>
              <path
                d='M0 0L24 0 24 24 0 24z'
                transform='translate(-1310 -84) translate(1310 84)'
              />
              <path
                fill='currentColor'
                fillRule='nonzero'
                d='M14.59 7.41L18.17 11H7v2h11.17l-3.59 3.59L16 18l6-6-6-6-1.41 1.41zM3 6v12h2V6H3zm0 0h8V4H3v2zm0 14h8v-2H3v2z'
                transform='translate(-1310 -84) translate(1310 84)'
              />
            </g>
          </g>
        </g>
      </svg>
    </Wrapper>
  )
}
