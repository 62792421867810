import React from 'react'
import Wrapper, { IIconProps } from './Wrapper'

export default (props: IIconProps) => {
  return (
    <Wrapper {...props}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='1em'
        height='1em'
        viewBox='0 0 24 24'
      >
        <g fill='none' fillRule='evenodd'>
          <g>
            <g>
              <path
                d='M0 0L24 0 24 24 0 24z'
                transform='translate(-1266 -84) translate(1266 84)'
              />
              <path
                fill='currentColor'
                fillRule='nonzero'
                d='M12.395 16.555L17.274 19.5 15.979 13.95 20.289 10.216 14.613 9.734 12.395 4.5 10.176 9.734 4.5 10.216 8.811 13.95 7.516 19.5z'
                transform='translate(-1266 -84) translate(1266 84)'
              />
              <path
                d='M0 0L24 0 24 24 0 24z'
                transform='translate(-1266 -84) translate(1266 84)'
              />
            </g>
          </g>
        </g>
      </svg>
    </Wrapper>
  )
}
