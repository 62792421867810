import React from 'react'
import Wrapper, { IIconProps } from './Wrapper'

export default (props: IIconProps) => {
  return (
    <Wrapper {...props}>
      <svg
        viewBox='0 0 24 24'
        height='1em'
        width='1em'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill='none' fillRule='evenodd'>
          <path d='m0 0h24v24h-24z' />
          <path
            d='m18.113 13.03c.032-.263.057-.526.057-.806s-.025-.542-.057-.806l1.735-1.357c.156-.123.197-.345.099-.526l-1.645-2.846c-.099-.18-.321-.246-.502-.18l-2.048.822c-.428-.33-.888-.6-1.39-.806l-.312-2.18c-.025-.197-.198-.345-.403-.345h-3.29c-.206 0-.378.148-.403.345l-.313 2.18c-.501.206-.962.485-1.39.806l-2.047-.823c-.19-.074-.403 0-.502.181l-1.645 2.846c-.107.181-.058.403.099.526l1.735 1.357c-.033.264-.058.535-.058.806 0 .272.025.543.058.806l-1.735 1.357c-.157.124-.198.346-.099.527l1.645 2.845c.099.181.32.247.502.181l2.047-.822c.428.329.889.6 1.39.806l.313 2.18c.025.197.197.345.403.345h3.29c.205 0 .378-.148.403-.346l.312-2.179c.502-.206.962-.485 1.39-.806l2.048.822c.19.074.403 0 .502-.18l1.645-2.846c.098-.181.057-.403-.1-.527zm-6.111 2.073c-1.587 0-2.879-1.291-2.879-2.879 0-1.587 1.292-2.878 2.879-2.878s2.878 1.291 2.878 2.878c0 1.588-1.29 2.879-2.878 2.879z'
            fill='currentColor'
            fillRule='nonzero'
          />
        </g>
      </svg>
    </Wrapper>
  )
}
