import React, { ReactNode } from 'react'
import { classNames } from '@madup-inc/utils'
import './Wrapper.scss'

export interface IIconProps {
  size?: number | string
  style?: any // FIXME 타입특정 필요. ex) React.StyleHTMLAttributes<HTMLDivElement>
  className?: string
  onClick?: any
  children?: ReactNode
}

export default ({ size, style, onClick, className, children }: IIconProps) => {
  return (
    <div
      style={{ fontSize: size, ...style }}
      onClick={onClick}
      className={classNames('mad-icon', className)}
    >
      {children}
    </div>
  )
}
