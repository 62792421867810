import React, { useState } from 'react'
import { ImageViewer } from './ImageViewer'
import './Image.scss'
// import { classNames } from '@madup-inc/utils'

interface Preview {
  /**
   * 미리보기 이미지 경로
   */
  src?: string
  message?: React.ReactNode
  onClose?: (e: any) => void
}

interface IProps {
  /**
   * 이미지 경로
   */
  src: string
  /**
   * 이미지 설명
   */
  alt?: string
  /**
   * 미리보기 이미지 경로
   */
  preview?: Preview
}

export const Image: React.FC<IProps> = ({ src, alt, preview }) => {
  const [visible, setVisible] = useState(false)
  return (
    <div className='mad-image'>
      <img className='mad-image-img' src={src} alt={alt} />
      {preview !== false && (
        <div className='mad-image-mask' onClick={() => setVisible(true)}>
          <div className='mad-image-mask-info'>
            {preview?.message || 'preview'}
          </div>
        </div>
      )}
      {preview !== false && visible && (
        <ImageViewer
          src={preview?.src || src}
          onClose={(e) => {
            setVisible(false)
            preview?.onClose?.(e) // eslint-disable-line
          }}
          alt={alt}
        />
      )}
    </div>
  )
}
