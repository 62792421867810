import React from 'react'
import './ImageViewer.scss'
// import { classNames } from '@madup-inc/utils'

interface IProps {
  /**
   * 이미지 경로
   */
  src: string
  /**
   * 이미지 설명
   */
  alt?: string
  onClose?: (e: any) => void
}

export const ImageViewer: React.FC<IProps> = ({ src, alt, onClose }) => {
  return (
    <div className='mad-image-viewer'>
      <span className='close-button' onClick={onClose}>
        ×
      </span>
      <div className='mad-image-viewer-wrapper' onClick={onClose}>
        <div className='mad-image-viewer-inner-wrapper'>
          <img
            className='mad-image-viewer-img'
            src={src}
            alt={alt}
            onClick={(e) => e.stopPropagation()}
          />
        </div>
      </div>
    </div>
  )
}
