import React, { FC, useRef } from 'react'
import './Tooltip.scss'
import { classNames, oneOf } from '@madup-inc/utils'
import { reversePositions } from '../utils'

type positionType =
  | 'topLeft'
  | 'top'
  | 'topRight'
  | 'leftTop'
  | 'left'
  | 'leftBottom'
  | 'bottomLeft'
  | 'bottom'
  | 'bottomRight'
  | 'rightTop'
  | 'right'
  | 'rightBottom'

interface ITooltipProps {
  style?: any
  children?: any
  title: any
  disabled?: boolean
  color?: string
  bgColor?: string
  type: 'hover' | 'click'
  className?: string
  position?: positionType
}

export const Tooltip: FC<ITooltipProps> = ({
  style,
  children,
  disabled = false,
  title,
  color,
  bgColor,
  type = 'hover',
  className,
  position = 'top',
}) => {
  const tooltipText = useRef<HTMLDivElement>(null)
  const tooltipArrow = useRef<HTMLDivElement>(null)

  const clickEventProps = type === 'click' && {
    onClick: (e: any) => handleTooltip(e.currentTarget),
  }

  const hoverEventProps = type === 'hover' && {
    onMouseEnter: (e: any) => handleTooltip(e.currentTarget),
    onMouseLeave: (e: any) => e.currentTarget.classList.remove(type),
  }

  const capitalizeFirstLetter = (string: string) => {
    const direction = string.split(/(?=[A-Z])/)[0]
    return direction.charAt(0).toUpperCase() + direction.slice(1)
  }

  const isInViewport = (element: HTMLDivElement) => {
    const rect = element.getBoundingClientRect()
    const positionName = element.classList[1]
    return oneOf(
      [
        [() => positionName.includes('top'), rect.top >= 0],
        [() => positionName.includes('left'), rect.left >= 0],
        [
          () => positionName.includes('bottom'),
          rect.bottom <=
            (window.innerHeight || document.documentElement.clientHeight),
        ],
        [
          () => positionName.includes('right'),
          rect.right <=
            (window.innerWidth || document.documentElement.clientWidth),
        ],
      ],
      true,
    )
  }

  const clickOrHoverShow = (tooltip: HTMLDivElement, clickHide: () => void) => {
    if (tooltipText.current !== null && tooltipArrow.current !== null) {
      const tooltipTextClasses = tooltipText.current.classList
      tooltipTextClasses.replace(tooltipTextClasses[1], position)

      if (!isInViewport(tooltipText.current)) {
        tooltipTextClasses.replace(
          tooltipTextClasses[1],
          reversePositions[position as keyof typeof reversePositions],
        )
      }
      if (bgColor) {
        tooltipArrow.current.style.borderColor = `transparent`
        tooltipArrow.current.style[
          `border${capitalizeFirstLetter(tooltipTextClasses[1])}Color`
        ] = `${bgColor}`
      }
      if (type === 'click') {
        document.body.addEventListener('click', clickHide)
      }
      tooltip.classList.add(type)
    }
  }

  const handleTooltip = (tooltip: HTMLDivElement) => {
    function clickHide() {
      tooltip.classList.remove(type)
      document.body.removeEventListener('click', clickHide)
    }
    clickOrHoverShow(tooltip, clickHide)
  }

  return (
    <div
      style={style}
      className={classNames('mad-tooltip', { disabled }, className)}
      {...(clickEventProps || hoverEventProps)}
    >
      <div className='mad-tooltip-title'>{title}</div>
      <div
        ref={tooltipText}
        className={classNames('mad-tooltip-text', position)}
        style={{
          color,
          backgroundColor: bgColor,
        }}
      >
        <div
          ref={tooltipArrow}
          className='mad-tooltip-arrow'
          style={{
            [`border${capitalizeFirstLetter(position)}Color`]: bgColor,
          }}
        />
        {children}
      </div>
    </div>
  )
}
