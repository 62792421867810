import React from 'react'
import Wrapper, { IIconProps } from './Wrapper'

export default (props: IIconProps) => {
  return (
    <Wrapper {...props}>
      <svg
        viewBox='0 0 24 24'
        height='1em'
        width='1em'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill='none' fillRule='evenodd'>
          <path d='m0 0h24v24h-24z' />
          <path
            d='m20 11h-12.173l5.586-5.587-1.413-1.413-8 8 8 8 1.413-1.413-5.586-5.587h12.173z'
            fill='currentColor'
            fillRule='nonzero'
          />
        </g>
      </svg>
    </Wrapper>
  )
}
