import { Playground, Props } from 'docz';
import { IconAdd, IconEdit, IconAnchor, IconArrowDown, IconArrowUp, IconArrowLeft, IconArrowRight, IconBack, IconBusiness, IconCaretDown, IconCaretDownCircle, IconClose, IconGuide, IconHome, IconChart, IconMenu, IconNotify, IconReport, IconSetting, IconSetting2, IconStar, IconStartSelected, IconThumb, IconUser, IconLogout } from '@madup-inc/mad-design';
import * as React from 'react';
export default {
  Playground,
  Props,
  IconAdd,
  IconEdit,
  IconAnchor,
  IconArrowDown,
  IconArrowUp,
  IconArrowLeft,
  IconArrowRight,
  IconBack,
  IconBusiness,
  IconCaretDown,
  IconCaretDownCircle,
  IconClose,
  IconGuide,
  IconHome,
  IconChart,
  IconMenu,
  IconNotify,
  IconReport,
  IconSetting,
  IconSetting2,
  IconStar,
  IconStartSelected,
  IconThumb,
  IconUser,
  IconLogout,
  React
};