import React from 'react'
import Wrapper, { IIconProps } from './Wrapper'
export default (props: IIconProps) => {
  return (
    <Wrapper {...props}>
      <svg
        viewBox='0 0 15 15'
        width='1em'
        height='1em'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <circle
          cx='7.5'
          cy='7.5'
          r='7.5'
          transform='rotate(-90 7.5 7.5)'
          fill='#C4C4C4'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M6 4.07345L6 4.76425C6 4.81123 6.02441 4.85544 6.06445 4.88307L9.88867 7.49983L6.06445 10.1166C6.02441 10.1442 6 10.1884 6 10.2354L6 10.9262C6 10.9861 6.07227 11.0211 6.12402 10.9861L10.8711 7.73838C11.043 7.62049 11.043 7.37917 10.8711 7.26219L6.12402 4.01451C6.07227 3.97858 6 4.01358 6 4.07345Z'
          fill='white'
        />
      </svg>
    </Wrapper>
  )
}
