import React from 'react'
import { classNames } from '@madup-inc/utils'
import './Space.scss'

interface IProps {
  /**
   * 여백의 사이즈를 정의합니다.
   */
  size?: 'small' | 'middle' | 'large'
  /**
   * 항목의 배열 방향을 결정합니다.
   */
  direction?: 'horizontal' | 'vertical'
  className?: string
  children: React.ReactNode
}

export const Space: React.FC<IProps> = ({
  size = 'middle',
  direction = 'horizontal',
  className,
  children,
}) => {
  return (
    <div className={classNames('mad-space', direction, size, className)}>
      {!Array.isArray(children) ? (
        <div className='mad-space-item'>{children}</div>
      ) : (
        children.map((child, idx) => (
          <div key={idx} className='mad-space-item'>
            {child}
          </div>
        ))
      )}
    </div>
  )
}
