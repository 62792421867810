import React, { useRef, useState, ReactNode } from 'react'
import { classNames } from '@madup-inc/utils'
import { Button } from './Button'
import { IconClose } from './icons'
import './Input.scss'

interface IProps {
  value?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onPressEnter?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  placeholder?: string
  disabled?: boolean
  type?: string
  allowClear?: boolean
  size: 'small' | 'middle' | 'large'
  addonAfter?: ReactNode
  addonBefore?: ReactNode
  style?: React.CSSProperties
  className?: string
}

export const Input: React.FC<IProps> = ({
  value,
  onChange,
  onPressEnter,
  placeholder,
  disabled = false,
  type = 'text',
  allowClear = false,
  size = 'middle',
  addonAfter,
  addonBefore,
  style,
  className,
}) => {
  const inputTag = useRef(null)
  const [clearVisible, setClearVisible] = useState(
    allowClear && value && value.length > 0,
  )

  const handlePressEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (onPressEnter && e.key === 'Enter') {
      onPressEnter(e)
    }
  }
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(e)
    if (allowClear) {
      if (e.target.value.length > 0) setClearVisible(true)
      else setClearVisible(false)
    }
  }
  const clearText = () => {
    if (inputTag.current !== null) {
      // @ts-ignore
      inputTag.current.value = ''
      setClearVisible(false)
    }
  }
  const convertStringToTag = (node: ReactNode) => {
    if (typeof node === 'string') return <p>{node}</p>
    return node
  }

  return (
    <div
      className={classNames('mad-input-wrapper', size, className, {
        disabled,
      })}
      style={style}
    >
      {addonBefore && (
        <div className='mad-input-before'>
          {convertStringToTag(addonBefore)}
        </div>
      )}
      <input
        value={value}
        onChange={(e) => handleOnChange(e)}
        placeholder={placeholder}
        disabled={disabled}
        type={type}
        onKeyDown={onPressEnter && ((e) => handlePressEnter(e))}
        className='mad-input'
        ref={inputTag}
      />
      {addonAfter && (
        <div className='mad-input-after'>{convertStringToTag(addonAfter)}</div>
      )}
      {clearVisible && (
        <Button type='text' className='clear-button' round onClick={clearText}>
          <IconClose size='1.25em' />
        </Button>
      )}
    </div>
  )
}
