import React from 'react'
import { MultipleSelect, SingleSelect } from './selectors'
import { ISelect } from './selectors/type'

export const Select: React.FC<ISelect> = (props) =>
  props.type !== 'multi' ? (
    <SingleSelect {...props} />
  ) : (
    <MultipleSelect {...props} />
  )
