import { Playground, Props } from 'docz';
import { Tag, Space, IconChart, IconAdd } from '@madup-inc/mad-design';
import * as React from 'react';
export default {
  Playground,
  Props,
  Tag,
  Space,
  IconChart,
  IconAdd,
  React
};