import React from 'react'
import Wrapper, { IIconProps } from './Wrapper'

export default (props: IIconProps) => {
  return (
    <Wrapper {...props}>
      <svg
        viewBox='0 0 24 24'
        height='1em'
        width='1em'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill='none' fillRule='evenodd'>
          <path d='m0 0h24v24h-24zm0 0h24v24h-24z' />
          <path
            d='m19.373 6.04-1.413-1.413-5.96 5.96-5.96-5.96-1.413 1.413 5.96 5.96-5.96 5.96 1.413 1.413 5.96-5.96 5.96 5.96 1.413-1.413-5.96-5.96z'
            fill='currentColor'
            fillRule='nonzero'
          />
        </g>
      </svg>
    </Wrapper>
  )
}
