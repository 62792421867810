import React from 'react'
import { classNames } from '@madup-inc/utils'
import { IconClose } from './icons'
import './Tag.scss'
import { presetColors } from '../utils'

interface IProps {
  children?: any
  /**
   * 태그의 생상 지정
   * @default white
   */
  color?: string
  icon?: any
  style?: any
  /**
   * 태그의 width값 없으면 children 따라감
   */
  width?: string | number
  closable?: boolean
  onClose?: () => void
}

export const Tag: React.FC<IProps> = ({
  children,
  color,
  icon = undefined,
  style,
  width,
  closable,
  onClose,
}) => {
  const hasPresetColor = presetColors.includes(color || '')
  return (
    <div
      style={{
        ...(!hasPresetColor &&
          color !== undefined && {
            color: '#fff',
            background: color,
            border: '1px solid ' + color,
          }),
        ...style,
        width,
      }}
      className={classNames('mad-tag', {
        default: !color,
        'has-color': color,
        [color || 'preset']: color,
      })}
    >
      {icon && <span className='icon'>{icon}</span>}
      <span>{children}</span>
      {closable && (
        <span className='close' onClick={onClose}>
          <IconClose size={16} />
        </span>
      )}
    </div>
  )
}
