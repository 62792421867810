import { Playground, Props } from 'docz';
import { Space, Button, Tag } from '@madup-inc/mad-design';
import * as React from 'react';
export default {
  Playground,
  Props,
  Space,
  Button,
  Tag,
  React
};