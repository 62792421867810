import React from 'react'
import { IIconProps } from './Wrapper'
import IconStar from './IconStar'

export default ({ size, style, onClick, className }: IIconProps) => {
  return (
    <IconStar
      style={{ color: 'rgb(255, 216, 0)', ...style }}
      size={size}
      onClick={onClick}
      className={className}
    />
  )
}
