import React from 'react'
import Wrapper, { IIconProps } from './Wrapper'

export default (props: IIconProps) => {
  return (
    <Wrapper {...props}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 24 24'
        width='1em'
        height='1em'
      >
        <g fill='none' fillRule='evenodd'>
          <g>
            <g>
              <path
                fill='currentColor'
                fillRule='nonzero'
                d='M4.5 16.375V19.5h3.125l9.215-9.215-3.125-3.125L4.5 16.375zm14.756-8.507c.325-.325.325-.85 0-1.175l-1.95-1.95c-.324-.324-.85-.324-1.174 0L14.607 6.27l3.124 3.124 1.525-1.525z'
                transform='translate(-1350 -84) translate(1350 84)'
              />
              <path
                d='M0 0L24 0 24 24 0 24z'
                transform='translate(-135ㄹ0 -84) translate(1350 84)'
              />
            </g>
          </g>
        </g>
      </svg>
    </Wrapper>
  )
}
