import React from 'react'
import Wrapper, { IIconProps } from './Wrapper'

export default (props: IIconProps) => {
  return (
    <Wrapper {...props}>
      <svg
        viewBox='0 0 24 24'
        height='1em'
        width='1em'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g fill='none' fillRule='evenodd'>
          <path d='m0 0h24v24h-24z' />
          <path
            d='m12 7v-4h-10v18h20v-14zm-6 12h-2v-2h2zm0-4h-2v-2h2zm0-4h-2v-2h2zm0-4h-2v-2h2zm4 12h-2v-2h2zm0-4h-2v-2h2zm0-4h-2v-2h2zm0-4h-2v-2h2zm10 12h-8v-2h2v-2h-2v-2h2v-2h-2v-2h8zm-2-8h-2v2h2zm0 4h-2v2h2z'
            fill='currentColor'
            fillRule='nonzero'
          />
        </g>
      </svg>
    </Wrapper>
  )
}
