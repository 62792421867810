import { Playground, Props } from 'docz';
import { Button, IconUser, Space, Input } from '@madup-inc/mad-design';
import { useState } from 'react';
import * as React from 'react';
export default {
  Playground,
  Props,
  Button,
  IconUser,
  Space,
  Input,
  useState,
  React
};