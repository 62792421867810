import React from 'react'
import { IIconProps } from './Wrapper'
import IconArrowRight from './IconArrowRight'

export default ({ size, style, onClick, className }: IIconProps) => {
  return (
    <IconArrowRight
      style={{ transform: 'rotate(90deg)', ...style }}
      size={size}
      onClick={onClick}
      className={className}
    />
  )
}
