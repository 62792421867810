import React from 'react'
import Wrapper, { IIconProps } from './Wrapper'

export default (props: IIconProps) => {
  return (
    <Wrapper {...props}>
      <svg
        viewBox='0 0 15 15'
        width='1em'
        height='1em'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <circle cx='7.5' cy='7.5' r='7.5' fill='#C4C4C4' />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M10.9265 6H10.2357C10.1888 6 10.1446 6.02441 10.1169 6.06445L7.50017 9.88867L4.88342 6.06445C4.85579 6.02441 4.81158 6 4.7646 6H4.0738C4.01393 6 3.97893 6.07227 4.01393 6.12402L7.26162 10.8711C7.37951 11.043 7.62083 11.043 7.73781 10.8711L10.9855 6.12402C11.0214 6.07227 10.9864 6 10.9265 6Z'
          fill='white'
        />
      </svg>
    </Wrapper>
  )
}
